@import '../../styles/utils';

.Technology {
  padding-top: 64px;

  @include onTablet {
    padding-top: 96px;
  }

  @include onDesktop {
    padding-top: 32px;
  }

  &__Title {
    @include applyFontBold;

    font-weight: 700;
    font-size: 48px;
    line-height: 59px;

    margin-bottom: 32px;

    @include onTablet {
      text-align: center;
      margin-bottom: 48px;
    }
  }

  &__Image {
    @include squere(32px);

    @include onTablet {
      @include squere(48px);
    }
  }

  &__List {
    list-style: none;

    @include onDesktop {
      max-width: 795px;
      margin: 0 auto;
    }
  }

  &__Frame {
    display: flex;
    align-items: flex-start;
    padding: 24px 16px;
    gap: 16px;

    background: #ffffff;
    box-shadow: 0px 4px 14px rgba(103, 129, 155, 0.15);
    border-radius: 6px;

    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    @include onTablet {
      padding: 32px;
      align-items: center;
    }
  }

  &__Text {
    @include applyFont;
    font-weight: 300;
    font-size: 24px;
    line-height: 30px;

  }

  &__Bold {
    @include applyFontBold;
    font-weight: 500;
  }
}
