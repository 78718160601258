@import '../../styles/utils';

.Services {
  --columns: 1;

  padding-top: 96px;
  margin-bottom: 48px;

  @include onTablet {
    --columns: 2;
  }

  @include onDesktop {
    --columns: 3;
    padding-top: 128px;
    margin-bottom: 96px;
  }

  &__Title {
    @include applyFontBold;

    font-weight: 700;
    font-size: 48px;
    line-height: 59px;

    margin: 0 auto 48px;

    max-width: 900px;

    @include onTablet {
      text-align: center;
    }
  }

  &__Frames {
    display: grid;
    grid-template-columns: repeat(var(--columns), 1fr);

    gap: 20px;
  }

  &__Frame {
    display: flex;
    gap: 16px;
    align-items: flex-start;

    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    &--Title {
      @include applyFontBold;

      font-weight: 500;
      font-size: 24px;
      line-height: 30px;

      margin-bottom: 16px;
    }

    &--Text {
      @include applyFont;

      font-weight: 300;
      font-size: 16px;
      line-height: 20px;
    }
  }
}
