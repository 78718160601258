@import '../../styles/utils';

.About {
  padding-top: 42px;

  @include onTablet {
    padding-top: 70px;
  }

  @include onDesktop {
    padding-top: 128px;
  }

  &__Content {
    @include onDesktop {
      display: flex;
      gap: 56px;
    }
  }

  &__Image {
    width: 100%;
    margin-bottom: 36px;

    @include onTablet {
      width: 345px;
    }

    @include onDesktop {
      @include squere(425px);
    }
  }

  &__Title {
    @include applyFontBold;
    font-weight: 700;
    font-size: 48px;
    line-height: 59px;

    margin-bottom: 24px;
  }

  &__Text {
    @include applyFont;
    font-weight: 300;
    font-size: 24px;
    line-height: 34px;

    border-bottom: 2px solid #4fadc3;
    padding-bottom: 32px;

    margin-bottom: 32px;
  }

  &__Options {
    @include onTablet {
      display: flex;
      justify-content: space-between;
      gap: 36px;
    }
  }

  &__Option {
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }

    @include onTablet {
      margin-bottom: 0;
      width: 33%;
    }

    &--Title {
      @include applyFontBold;

      font-weight: 700;
      font-size: 48px;
      line-height: 59px;

      margin-bottom: 8px;

      @include onTablet {
        font-size: 32px;
        line-height: 40px;
      }
    }

    &--Text {
      @include applyFont;

      font-weight: 300;
      font-size: 24px;
      line-height: 34px;

      @include onTablet {
        font-size: 18px;
        line-height: 22px;
      }
    }
  }
}
