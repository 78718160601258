@import "../../styles/utils";

.Header {
  background-color: #fff;

  &__Logo {
    background-image: url(../../images/header.png);
    @include setBackground(128px, 28px);

    @include onTablet {
      width: 165px;
      height: 36px;
    }
  }

  &__Content {
    height: 70px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    @include onTablet {
      height: 82px;
    }
  }

  &__Button {
    background: linear-gradient(94.72deg, #e6456a 3.96%, #d9368e 126.25%);
    border-radius: 6px;
    @include centerContent;
    

    padding: 8px;

    @include onTablet {
      padding: 12px 30px;
    }

    &-Icon {
      background-image: url(../../images/header-mail.svg);
      @include setBackground(20px, 20px);

      @include onTablet {
        display: none;
      }
    }

    &-Text {
      display: none;

      font-family: "PBS Sans";
      font-style: normal;
      font-weight: 900;
      font-size: 16px;
      line-height: 20px;

      color: #fff;

      @include onTablet {
        display: inline;
      }
    }
  }
}
