@import '../../styles/utils';

.Review {
  position: relative;
  padding-bottom: 130px;

  margin: 0 auto;

  @include onTablet {
    max-width: 768px;
  }

  @include onDesktop {
    max-width: 1440px;
  }

  @include onDesktop {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 320px;

    height: 930px;
  }

  &__Content {
    padding: 0 20px;

    @include onTablet {
      padding: 0 100px;
    }

    @include onDesktop {
      padding: 152px 100px 0 0;
      width: 50%;
    }
  }

  &__Image {
    width: 100%;
    margin-bottom: 16px;

    z-index: -1;

    @include onTablet {
      padding: 0 90px;
    }

    @include onDesktop {
      position: absolute;
      width: 900px;
      top: 0;
      left: 0;
      padding: 0;
    }
  }

  &__Title {
    @include applyFontBold;

    font-weight: 700;
    font-size: 48px;
    line-height: 59px;

    color: #ffffff;

    margin-bottom: 24px;

    @include onTablet {
      text-align: center;
    }

    @include onDesktop {
      text-align: start;
    }
  }

  &__Text {
    @include applyFont;

    font-weight: 300;
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 28px;

    color: #fff;

    @include onTablet {
      text-align: center;
    }

    @include onDesktop {
      text-align: start;
    }
  }

  &__Button {
    @include applyFontBold;
    @include centerContent;

    padding: 16px 30px;
    max-width: 230px;

    background: #ffffff;
    border-radius: 6px;

    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;

    color: #0381ff;

    @include onTablet {
      margin: 0 auto;
    }

    @include onDesktop {
      margin: 0;
    }
  }
}
