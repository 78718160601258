@import '../../styles/utils';

.Level2 {
  background-image: url(../../images/level2-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 96px;
  padding-bottom: 64px;

  @include onTablet {
    padding-top: 124px;
    padding-bottom: 100px;
  }

  &__Title {
    @include applyFontBold;

    font-weight: 700;
    font-size: 48px;
    line-height: 59px;
    color: #fff;

    margin-bottom: 32px;

    @include onTablet {
      text-align: center;
    }

    @include onDesktop {
      max-width: 790px;
      margin: 0 auto 32px;
    }
  }

  &__Frames {
    margin-bottom: 64px;

    @include onDesktop {
      display: flex;
      margin-bottom: 128px;
      gap: 20px;
    }
  }

  &__Frame {
    padding: 32px 16px;
    margin-bottom: 20px;

    @include onTablet {
      max-width: 387px;
      margin: 0 auto 20px;
    }

    @include onDesktop {
      margin: 0;
      height: 256px;
      width: 33%;
    }

    &:last-child {
      margin-bottom: 0;
    }

    background: linear-gradient(
      102.16deg,
      rgba(255, 255, 255, 0.045) -2.95%,
      rgba(255, 255, 255, 0.198) 110.63%
    );
    backdrop-filter: blur(6px);
    border-radius: 6px;

    &--Image {
      margin-bottom: 24px;
    }
  }

  &__Text {
    @include applyFont;

    font-weight: 300;
    font-size: 24px;
    line-height: 30px;

    color: #fff;

    &--Graph {
      margin-bottom: 32px;
    }
  }

  &__Subtitle {
    margin-bottom: 32px;
    @include applyFontBold;

    color: #fff;

    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
  }

  &__Graph {
    width: 100%;

    @include onDesktop {
      min-width: 584px;
    }
  }

  &__Table {
    width: 100%;

    @include onDesktop {
      min-width: 578px;
    }
  }

  &__Value {
    margin-bottom: 102px;

    @include onDesktop {
      display: flex;
      gap: 82px;

      margin-bottom: 90px;
    }
  }

  &__Ad {
    @include onDesktop {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      gap: 60px;
    }
  }
}
