@import './utils';
@import './reset';

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  height: 100%;
}

a {
  text-decoration: none;
}