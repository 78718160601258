@import '../../styles/utils';

.Level1 {
  padding-top: 64px;
  margin-bottom: 64px;

  @include onTablet {
    padding-top: 96px;
    margin-bottom: 96px;
  }

  @include onDesktop {
    padding-top: 128px;
    margin-bottom: 128px;
  }

  &__Content {
    @include onDesktop {
      display: flex;
      gap: 32px;
    }
  }

  &__Image {
    width: 100%;
    height: 100%;
    object-fit: contain;

    &-Container {
      margin-bottom: 32px;

      @include onDesktop {
        min-width: 452px;
        margin-bottom: 0;
      }
    }
  }

  &__Title {
    @include applyFontBold;

    font-weight: 700;
    font-size: 48px;
    line-height: 59px;

    margin-bottom: 32px;
  }

  &__List {
    list-style-position: inside;
  }

  &__Item {
    display: flex;
    gap: 20px;

    @include applyFont;

    font-weight: 300;
    font-size: 24px;
    line-height: 30px;

    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
