@import '../../styles/utils';

.Footer {
  padding-top: 48px;

  &__About {
    max-width: 370px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;

    margin-bottom: 28px;
  }

  &__Content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__Logo {
    background-image: url(../../images/header.png);
    @include setBackground(220px, 48px);

    margin-bottom: 24px;

    @include onTablet {
      width: 165px;
      height: 36px;
    }
  }

  &__Nav {
    margin-bottom: 28px;
  }

  &__Item {
    text-align: center;

    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__Link {
    @include applyFontBold;

    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    color: #000;
  }

  &__Copyright {
    @include applyFont;

    font-weight: 300;
    font-size: 14px;
    line-height: 17px;

    text-align: center;

    padding: 12px 0;

    border-top: 1px solid #d9d9d9;

    width: 100%;
  }

  &__Top {
    @include onDesktop {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      padding-right: 250px;
    }
  }
}
