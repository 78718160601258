@import '../../styles/utils';

.Level3 {
  padding-top: 86px;

  @include onTablet {
    padding-top: 130px;
  }

  @include onDesktop {
    padding-top: 128px;
  }

  &__Row {
    gap: 32px;

    @include onDesktop {
      display: flex;
    }

    &:first-child {
      flex-direction: row-reverse;
      margin-bottom: 75px;
    }
  }

  &__Title {
    @include applyFontBold;

    font-weight: 700;
    font-size: 48px;
    line-height: 59px;

    margin-bottom: 48px;

    @include onTablet {
      text-align: center;
    }
  }

  &__Image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin-bottom: 32px;

    &-Container {
      width: 100%;
      max-width: 416px;

      @include onDesktop {
        min-width: 416px;
      }

      &:first-of-type {
        grid-column: 2;
      }
    }
  }

  &__Subtitle {
    @include applyFontBold;

    font-weight: 900;
    font-size: 32px;
    line-height: 40px;

    margin-bottom: 24px;
  }

  &__Info {
    margin-bottom: 64px;

    &:last-child {
      margin-bottom: 0;
    }

    &:first-of-type {
      grid-area: 1 / 1;
    }

    @include onDesktop {
      padding-top: 80px;
    }
  }

  &__List {
    list-style-position: inside;
  }

  &__Item {
    display: flex;
    gap: 20px;

    @include applyFont;

    font-weight: 300;
    font-size: 24px;
    line-height: 30px;

    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
