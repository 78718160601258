@import '../../styles/utils';

.Contacts {
  background-image: url(../../images/contacts-bg.png);
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;

  padding: 128px 0 64px;

  @include onTablet {
    padding-bottom: 100px;
  }

  @include onDesktop {
    padding: 148px 0 128px;
  }

  color: #fff;

  &__Content {
    @include onDesktop {
      display: grid;
      grid-template-columns: 1fr 590px;
      gap: 50px;
    }
  }

  &__Title {
    @include applyFontBold;
    font-weight: 700;
    font-size: 48px;
    line-height: 59px;

    margin-bottom: 28px;
  }

  &__Description {
    @include applyFont;

    font-weight: 300;
    font-size: 24px;
    line-height: 34px;

    margin-bottom: 28px;

    @include onTablet {
      margin-bottom: 30px;
    }
  }

  &__List {
    list-style: none;
  }

  &__Item {
    display: flex;
    align-items: center;

    margin-bottom: 24px;

    &:nth-child(2) > a {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 2px;
        height: 1px;
        background-color: #fff;
      }
    }

    &:last-child {
      margin-bottom: 48px;

      @include onTablet {
        margin-bottom: 64px;
      }
    }
  }

  &__Link {
    @include applyFont;

    font-weight: 300;
    font-size: 24px;
    line-height: 30px;

    color: #fff;

    text-decoration: none;

    margin-left: 15px;
    transform: translateY(2px);
  }

  &__Map {
    align-self: flex-start;
  }

  &__Form {
    width: 100%;
  }
}
