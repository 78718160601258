@import './styles/utils';

.App {
  overflow-x: hidden;
  max-width: 100vw;

  &__Background {
    position: absolute;

    background-repeat: no-repeat;

    z-index: -1;
    width: 100%;
    height: 100%;
    background-size: cover;

    &--1 {
      background-image: url(./images/top-bg.png);

      background-position: bottom;
      top: 0;
    }

    &--2 {
      background-image: url(./images/technology-bg.png);
      right: -10%;
      bottom: 20%;
    }

    &--3 {
      background-image: url(./images/technology-bg-2.png);
      background-size: contain;
      top: 10%;
      left: -20%;

      @include onDesktop {
        display: none;
      }
    }

    &--4 {
      background-image: url(./images/technology-bg.png);
      background-size: contain;
      width: 70%;
      height: 70%;

      bottom: -30%;
      right: 0;

      @include onTablet {
        bottom: -20%;
        right: -10%;
      }

      @include onDesktop {
        bottom: -40%;
        right: unset;
        left: 0;
      }
    }

    &--5 {
      background-image: url(./images/technology-bg-2.png);
      background-size: contain;
      width: 70%;
      height: 70%;

      top: 15%;
      right: 0;

      @include onTablet {
        top: 10%;
        left: 0;
      }

      @include onDesktop {
        top: 10%;
        right: -30%;
        left: unset;
      }
    }
  }
}
