@import '../../styles/utils';

.Container {
  max-width: 375px;
  padding: 0 20px;

  margin: 0 auto;

  @include onTablet {
    max-width: 768px;
    padding: 0 40px;
  }

  @include onDesktop {
    max-width: 1440px;
    padding: 0 120px;
  }
}