@import '../../styles/utils';

.Form {
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: 48px 32px;
  border-radius: 6px;
  box-shadow: 0px 4px 14px rgba(103, 129, 155, 0.15);
  background-color: #fff;

  @include onTablet {
    padding: 48px 20px;
  }

  &__Title {
    @include applyFontBold;

    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 32px;

    color: #000;
  }

  &__Label {
    position: relative;

    display: flex;
    flex-direction: column;
    gap: 12px;

    margin-bottom: 32px;

    @include applyFont;

    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #000;
  }

  &__Input {
    border: 1px solid #cecece;
    border-radius: 6px;
    outline: none;

    padding: 16px 24px;

    @include applyFont;

    font-weight: 300;
    font-size: 18px;
    line-height: 22px;

    color: #000;

    &::placeholder {
      color: #000;
    }

    @include onTablet {
      padding: 12px 18px;
    }
  }

  &__Button {
    display: flex;
    justify-content: center;
    align-items: center;

    background: linear-gradient(94.72deg, #e6456a 3.96%, #d9368e 126.25%);
    border: none;
    outline: none;
    border-radius: 6px;

    @include applyFontBold;

    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    padding: 16px 0;

    margin-bottom: 16px;

    color: #fff;

    height: 50px;

    cursor: pointer;
  }

  &__Terms {
    @include applyFont;

    font-weight: 300;
    font-size: 16px;
    line-height: 20px;

    color: #808080;
    text-align: center;
  }

  &__Incorrect {
    @include applyFont;

    position: absolute;
    left: 0;
    bottom: -24px;

    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    color: #FF2257;
  }
}
