// Media Queries
@mixin onTablet {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin onDesktop {
  @media (min-width: 1260px) {
    @content;
  }
}


// Fonts
@mixin applyFont {
  font-family: 'PBSSans', sans-serif;
  font-style: normal;
}

@mixin applyFontBold {
  font-family: 'PBSSans-Bold', sans-serif;
  font-style: normal;
}

// Background
@mixin setBackground($width, $height) {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: $width;
  height: $height;
}

// Position
@mixin centerContent {
  display: flex;
  justify-content: center;
  align-items: center;
}

// Size
@mixin squere($side) {
  width: $side;
  height: $side;
}